import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MobileUser } from '@data/mobile-user/mobile-user.model';

export interface MobileUserState extends EntityState<MobileUser> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'mobile-user', resettable: true, idKey: '_id' })
export class MobileUserStore extends EntityStore<MobileUserState, MobileUser> {
  constructor() {
    super();
    this.createUIStore();
  }
}
