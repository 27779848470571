import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SignalService {
  private listeners: SignalListener[] = [];

  constructor() {}

  registerSignalListener(listener: SignalListener) {
    this.listeners.push(listener);
  }

  unregisterSignalListener(listener: SignalListener) {
    this.listeners.filter((it) => it.id !== listener.id);
  }

  notifySignalListener(signal: string) {
    this.listeners.forEach((it) => {
      if (it.signal === signal) {
        it.onSignalReceived();
      }
    });
  }
}

export interface SignalListener {
  id: string;
  signal: string;

  onSignalReceived(): void;
}
