import { Component, EventEmitter, Output } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { Match } from '@data/match/match.model';
import { ValidationStatus } from '@core/constants/match/status';
import { enumKeys } from '@shared/util/enum.util';

@Component({
  selector: 'recrewt-match-move-menu',
  templateUrl: './match-move-menu.component.html',
  styleUrls: ['./match-move-menu.component.scss'],
})
export class MatchMoveMenuComponent extends MenuComponent<Match> {
  @Output() changeStatus = new EventEmitter<ValidationStatus>();

  VALIDATION_STATUS_KEYS = enumKeys(ValidationStatus);

  ValidationStatus = ValidationStatus;

  strategy: MatchMenuStrategy = {
    showStatus(status: number, match: Match): boolean {
      return match.validationStatus !== status && status !== ValidationStatus.NEW;
    },
  };
}

export interface MatchMenuStrategy extends MenuStrategy {
  showStatus(status: number, match: Match): boolean;
}
