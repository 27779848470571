export const REQUIREMENTS = [
  'MAPPING.REQUIREMENTS.appearance',
  'MAPPING.REQUIREMENTS.skills',
  'MAPPING.REQUIREMENTS.knowhow',
  'MAPPING.REQUIREMENTS.strengths',
  'MAPPING.REQUIREMENTS.talents',
  'MAPPING.REQUIREMENTS.interests',
  'MAPPING.REQUIREMENTS.enthusiasm',
  'MAPPING.REQUIREMENTS.readiness',
  'MAPPING.REQUIREMENTS.fun',
  'MAPPING.REQUIREMENTS.grades',
  'MAPPING.REQUIREMENTS.subjects',
  'MAPPING.REQUIREMENTS.other',
];
