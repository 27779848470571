import { Pipe, PipeTransform } from '@angular/core';
import { DecToStrPipe } from '@shared/pipes/map-data.pipe';
import { CardinalMapping } from '../util/mapper.util';

@Pipe({
  name: 'mapList',
})
export class DecToStrListPipe extends DecToStrPipe implements PipeTransform {
  transform(arr: any, mapping: CardinalMapping): any {
    if (!Array.isArray(arr)) {
      return arr;
    }
    if (mapping) {
      arr.forEach((value) => {
        super.transform(value, mapping);
      });
      return arr;
    }
    return arr.map((value) => super.transform(value, mapping));
  }
}
