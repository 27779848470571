import auth_config from '../../auth_config.json';

export const environment = {
  production: false,
  auth: {
    domain: auth_config.domain,
    clientId: auth_config.clientId,
    redirectUri: window.location.origin + '/matching',
    audience: auth_config.audience,
  },
  apiUrl: () => 'https://test.api.recrewt.de/api/admin',
};
