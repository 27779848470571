import { Component, Input } from '@angular/core';

export interface StatusTagConfig {
  backgroundColor: string;
  color: string;
  text: string;
  desc?: string;
  icon?: string;
}

@Component({
  selector: 'recrewt-status-tag',
  templateUrl: './status-tag.component.html',
  styleUrls: ['./status-tag.component.scss'],
})
export class StatusTagComponent {
  @Input() config?: StatusTagConfig;
}
