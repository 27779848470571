import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'px',
})
export class PxPipe implements PipeTransform {
  transform(value: number, type: 'px' | '%' = 'px'): string {
    return `${value}${type}`;
  }
}
