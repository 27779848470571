import { Pipe, PipeTransform } from '@angular/core';
import { Company } from '@data/company/company.model';
import { CompanyQuery } from '@data/company/company.query';

@Pipe({
  name: 'companyRefToField',
})
export class CompanyRefToFieldPipe implements PipeTransform {
  constructor(private companies: CompanyQuery) {}

  transform(value: string | undefined, field: keyof Company, fallbackField?: keyof Company): any {
    const company = this.companies.getEntity(value);
    if (!company) {
      return undefined;
    }
    const data = company?.[field];
    if (!data && fallbackField) {
      return company?.[fallbackField];
    }
    return data;
  }
}
