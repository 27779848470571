import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'recrewt-info-card-layout',
  templateUrl: './info-card-layout.component.html',
  styleUrls: ['./info-card-layout.component.scss'],
})
export class InfoCardLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
