import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { JobGeneral } from './job-general.model';

export interface JobGeneralState extends EntityState<JobGeneral, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'job-general', resettable: true, idKey: '_id' })
export class JobGeneralStore extends EntityStore<JobGeneralState, JobGeneral, string> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
