import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'translateList',
})
export class TranslateListPipe extends TranslatePipe implements PipeTransform {
  transform(arr: any, field?: string): any[] {
    if (!Array.isArray(arr)) {
      return arr;
    }
    if (field) {
      arr.forEach((str) => {
        str[field] = super.transform(`${str[field]}`);
      });
      return arr;
    }
    return arr.map((str) => super.transform(`${str}`));
  }
}
