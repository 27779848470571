import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  NgForm,
} from '@angular/forms';
import { SearchPopupHandlerComponent } from '@shared/components/popup/base/search-popup-handler.component';

@Component({
  selector: 'recrewt-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent,
    },
  ],
})
export class SelectComponent extends SearchPopupHandlerComponent implements OnInit {
  @Input() matStyle = false;

  @Input() placeholder = '';

  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() error = '';

  @Input() require = false;

  @Input() isInvalid = false;

  selectControlName = 'select';

  selectFormGroup = new UntypedFormGroup({});

  get value(): any {
    return this.optionValue(this.model);
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.matStyle) {
      this.yOffset = -24;
    }
    const selection = this.displayValue(this.model);
    this.selectFormGroup.addControl(
      this.selectControlName,
      new UntypedFormControl(selection ? this.translate.instant(selection) : null),
    );
  }

  select(option: any): void {
    super.select(option);
    if (!this.parentFormGroup || !this.selectControlName) {
      return;
    }
    this.selectFormGroup.controls[this.selectControlName].setValue(
      this.translate.instant(this.displayValue(option)),
    );
  }

  close(): void {
    super.close();
    if (!this.parentFormGroup || !this.selectControlName) {
      return;
    }
    this.selectFormGroup.controls[this.selectControlName].markAsTouched();
  }
}
