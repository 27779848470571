<div class="width-match-parent">
  <div fxLayout="row" cdkDropListGroup fxLayoutAlign="center start" fxLayoutGap="24px"
       [ngStyle]="{'min-width.px': 334 * maxColsPerRow}"
       class="p-t-6">
    <div fxLayout="column" fxLayoutGap="24px" fxLayoutAlign="start center" class="p-b-4">
      <div fxLayout="row" fxLayoutGap="24px" *ngFor="let columns of columnsPerRow">
        <ng-container *ngFor="let col of columns">
          <recrewt-dnd-match-column
            [headline]="col.name"
            [items]="col.items"
            (moved)="col.onMoved($event)"
            (itemClicked)="col.onClick($event)"
            [allowDrop]="col.allowDrop"
            [itemTpl]="itemTpl"
          ></recrewt-dnd-match-column>
        </ng-container>
      </div>
    </div>
  </div>
</div>
