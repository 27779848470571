<div *ngIf="icon" class="main-icon m-b-4">
  <mat-icon *ngIf="icon !== 'recrewt'" class="on-surface-medium m-1">{{ icon }}</mat-icon>
  <img *ngIf="icon === 'recrewt'" alt="Icon" class="tint-gray recrewt-icon" src="assets/images/RC_Logo_Icon.svg" />
</div>
<h2 class="m-b-1">{{ headline | translate }}</h2>
<p class="on-surface-medium empty-state-text m-b-3">{{ text | translate }}</p>
<button (click)="buttonClicked.emit()" *ngIf="!!buttonText?.length" color="accent" mat-raised-button>
  <mat-icon *ngIf="!!buttonIcon">{{ buttonIcon }}</mat-icon>
  {{ buttonText | translate }}
</button>
