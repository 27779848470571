import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserStore } from '@data/user/user.store';
import { resetStores } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'recrewt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'recrewt-matcher-tool';

  constructor(
    private auth0: AuthService,
    private userStore: UserStore,
    private translate: TranslateService,
  ) {
    translate.setDefaultLang('de');
  }

  ngOnInit(): void {
    this.auth0.idTokenClaims$.pipe(untilDestroyed(this)).subscribe((user) => {
      if (this.userStore.userHasChanged(user?.sub ?? '')) {
        resetStores();
      }
      const lastLogin = Date.parse(user?.updated_at ?? '');
      this.userStore.loginUser({
        _id: user?.sub ?? '',
        lastLogin,
      });
    });
  }
}
