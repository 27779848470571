import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { fromEvent, merge, of, ReplaySubject } from 'rxjs';
import { delay, map, switchMap, takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[delayed-hover]',
})
export class DelayedHoverDirective implements OnInit, OnDestroy {
  @Input()
  delay = 1500;

  @Output('delayed-hover') hoverEvent = new EventEmitter();

  private destroyed$ = new ReplaySubject(1);

  constructor(private readonly element: ElementRef) {}

  ngOnInit(): void {
    const hide$ = fromEvent(this.element.nativeElement, 'mouseleave').pipe(map(() => false));
    const show$ = fromEvent(this.element.nativeElement, 'mouseenter').pipe(map(() => true));

    merge(hide$, show$)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((show) => {
          if (!show) {
            return of(false);
          }
          return of(true).pipe(delay(this.delay));
        }),
      )
      .subscribe((show) => {
        if (show) {
          this.hoverEvent.emit();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
