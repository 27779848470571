import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApplicationStore } from '@data/application/application.store';
import { Application, ApplicationData } from '@data/application/application.model';
import { DataBaseService } from '@data/data.base.service';
import { ApplicationQuery } from '@data/application/application.query';
import { ApplicationValidationStatus } from '@core/constants/match/status';
import { MobileUser } from '@data/mobile-user/mobile-user.model';
import { MobileUserStore } from '@data/mobile-user/mobile-user-store';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService extends DataBaseService<Application> {
  constructor(
    private userStore: MobileUserStore,
    applicationStore: ApplicationStore,
    applicationQuery: ApplicationQuery,
    http: HttpClient,
  ) {
    super(http, applicationStore, applicationQuery, 'job_applications');
  }

  publish(applData: ApplicationData[]): Observable<any> {
    return this.http.put(`${this.url}/publish`, applData).pipe(
      tap(() =>
        this.store.update(
          applData.map((it) => it.applId),
          { status: ApplicationValidationStatus.PUBLISHED },
        ),
      ),
    );
  }

  updateStatus(id: string, status: number): Observable<any> {
    return this.http.put(`${this.url}/status/${id}`, { status });
  }

  getDownloadUrl(
    applicationId: string | null,
    userId: string | null,
    onload: (url: string) => void,
  ) {
    return this.downloadApplication(applicationId, userId, onload);
  }

  fix(
    aid: string,
    uid: string,
    changes: { application: Partial<Application>; user: Partial<MobileUser> },
  ) {
    return this.http.put(`${this.url}/${uid}/${aid}/fix`, changes).pipe(
      tap(() => {
        this.store.update(aid, changes.application);
        this.userStore.update(uid, changes.user);
      }),
    );
  }

  private downloadApplication(
    applId: string | null,
    userId: string | null,
    onload: (url: string) => void,
  ) {
    if (!applId || !userId) return EMPTY;
    return this.download(applId, userId).pipe(
      tap((it) => {
        onload(URL.createObjectURL(new Blob([it], { type: 'application/pdf' })));
      }),
    );
  }

  private download(aid: string, uid: string) {
    return this.http.get(`${this.url}/${uid}/${aid}/download`, { responseType: 'blob' });
  }

  private loadAsUrl(application: Blob | undefined, onload: (url: string) => void) {
    if (!application) return;
    const reader = new FileReader();
    reader.onload = () => {
      onload(reader.result as string);
    };
    reader.readAsDataURL(application);
  }
}
