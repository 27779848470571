import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ColumnConfig, Identifiable, TableConfig } from './column-config';
import { countBy, groupBy } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'recrewt-dnd-table',
  templateUrl: './dnd-table.component.html',
  styleUrls: ['./dnd-table.component.scss'],
})
export class DndTableComponent implements OnInit {
  @Input() config?: TableConfig<any>;

  @Input() itemTpl?: TemplateRef<any>;

  maxColsPerRow = 3;

  columnsPerRow: ColumnConfig<Identifiable>[][] = [];

  ngOnInit(): void {
    const values = countBy(this.config?.columns ?? [], (it) => it.row);
    this.maxColsPerRow = Math.max(...Object.values(values));
    this.columnsPerRow = Object.values(groupBy(this.config?.columns ?? [], (it) => it.row));
  }
}
