import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface UserState {
  _id?: string;
  lastLogin?: number;
}

export function createInitialState(): UserState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'user',
  resettable: true,
})
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }

  loginUser(user: UserState): boolean {
    const userChanged = this.userHasChanged(user._id?.toString());
    if (!this.getValue()._id || userChanged) {
      this.update(user);
      return true;
    }
    return false;
  }

  userHasChanged(uid?: string): boolean {
    return this.getValue()._id?.toString() !== uid;
  }
}
