import { Component, EventEmitter, Output } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { enumKeys } from '@shared/util/enum.util';
import { ApplicationValidationStatus } from '@core/constants/match/status';
import { Application } from '@data/application/application.model';

@Component({
  selector: 'recrewt-application-move-menu',
  templateUrl: './application-move-menu.component.html',
  styleUrls: ['./application-move-menu.component.scss'],
})
export class ApplicationMoveMenuComponent extends MenuComponent<Application> {
  @Output() changeStatus = new EventEmitter<ApplicationValidationStatus>();

  VALIDATION_STATUS_KEYS = enumKeys(ApplicationValidationStatus);

  ApplicationValidationStatus = ApplicationValidationStatus;

  strategy: MatchMenuStrategy = {
    showStatus(status: ApplicationValidationStatus, application: Application): boolean {
      const isDifferentStatus = application.status !== status;
      const isNotMoveToNew = status !== ApplicationValidationStatus.NEW;
      const isNotMoveToPublish = status !== ApplicationValidationStatus.PUBLISHED;
      const isNotYetPublished = application.status < ApplicationValidationStatus.PUBLISHED;
      return isDifferentStatus && isNotMoveToNew && isNotMoveToPublish && isNotYetPublished;
    },
  };
}

export interface MatchMenuStrategy extends MenuStrategy {
  showStatus(status: number, application: Application): boolean;
}
