export class ArrayUtil {
  public static toMap(arr: any[], keyFn: (item: any) => any): Map<any, any> {
    const map = new Map();
    arr.forEach((it) => map.set(keyFn(it), it));
    return map;
  }
}

export function compare(
  a?: number | string | boolean,
  b?: number | string | boolean,
  isAsc?: boolean,
): number {
  if (typeof a === 'boolean') {
    a = a ? 1 : -1;
  }
  if (typeof b === 'boolean') {
    b = b ? 1 : -1;
  }
  if (!a) {
    return isAsc ? -1 : 1;
  }
  if (!b) {
    return isAsc ? 1 : -1;
  }
  if (a === b) {
    return 0;
  }
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
