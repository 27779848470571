import { Component } from '@angular/core';

@Component({
  selector: 'recrewt-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  isExpanded = false;

  sideNavClick = false;

  logoWithName = 'assets/images/RC_Logo_white.svg';

  logo = 'assets/images/RC_Logo_Icon.svg';

  expand(): void {
    if (!this.isExpanded && !this.sideNavClick) {
      this.isExpanded = true;
    }
  }

  collapse(): void {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
    this.sideNavClick = false;
  }
}
