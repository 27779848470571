import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Job } from './job.model';

export interface JobState extends EntityState<Job, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'job', resettable: true, idKey: '_id' })
export class JobStore extends EntityStore<JobState, Job, string> {
  constructor() {
    super();
  }
}
