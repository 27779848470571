import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { appRouteNames } from './app.routing.names';
import { ToolbarLayoutComponent } from './layout/toolbar-layout/toolbar-layout.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { InfoCardLayoutComponent } from './layout/info-card-layout/info-card-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'company',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ToolbarLayoutComponent,
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      {
        component: ContentLayoutComponent,
        path: appRouteNames.MATCHES,
        loadChildren: () =>
          import('./modules/matching/matching.module').then((m) => m.MatchingModule),
      },
      {
        component: ContentLayoutComponent,
        path: appRouteNames.APPLICATION,
        loadChildren: () =>
          import('./modules/application/application.module').then((m) => m.ApplicationModule),
      },
      {
        component: ContentLayoutComponent,
        path: appRouteNames.COMPANY,
        loadChildren: () => import('./modules/company/company.module').then((m) => m.CompanyModule),
      },
    ],
  },
  {
    path: appRouteNames.AUTH,
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: appRouteNames.ERROR,
    component: InfoCardLayoutComponent,
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
