import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Company } from '@data/company/company.model';

export interface CompanyState extends EntityState<Company> {}

export function createInitialState(): Company {
  return {
    _id: '',
    identifiableName: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company', resettable: true, cache: { ttl: 600000 }, idKey: '_id' })
export class CompanyStore extends EntityStore<CompanyState, Company> {
  constructor() {
    super(createInitialState());
  }
}
