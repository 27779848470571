<mat-menu #contextMenu="matMenu">
  <ng-template let-item="target" matMenuContent>
    <button
      (click)="onEditSaveState(item)"
      [disabled]="!strategy.showEditSaveState(item)"
      mat-menu-item
    >
      <mat-icon>edit</mat-icon>
      <span>{{ 'MENUS.USERS.edit' | translate }}</span>
    </button>
    <button
      (click)="onGenerateCompanyMatches(item)"
      [disabled]="!strategy.showGenerate(item)"
      mat-menu-item
    >
      <mat-icon>business</mat-icon>
      <span>{{ 'MENUS.USERS.generate_company' | translate }}</span>
    </button>
    <button
      (click)="onGenerateGeneralMatches(item)"
      [disabled]="!strategy.showGenerate(item)"
      mat-menu-item
    >
      <mat-icon>favorite</mat-icon>
      <span>{{ 'MENUS.USERS.generate_general' | translate }}</span>
    </button>
  </ng-template>
</mat-menu>
