import { Component } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { CURRENT_QUEST_DONE, SaveState } from '@data/mobile-user/mobile-user.model';
import { MobileUserService } from '@data/mobile-user/mobile-user.service';
import { DialogService } from '@core/services/dialog/dialog.service';
import { SaveStateEditComponent } from '../../../modules/matching/components/save-state-edit/save-state-edit.component';
import { MatchService } from '@data/match/match.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { ConfirmDialogComponent, ConfirmDialogData, } from '@shared/components/dialog/confirm/confirm-dialog.component';

@Component({
  selector: 'recrewt-mobile-user-menu',
  templateUrl: './mobile-user-menu.component.html',
  styleUrls: ['./mobile-user-menu.component.scss'],
})
export class MobileUserMenuComponent extends MenuComponent<SaveState> {
  strategy: MobileUserMenuStrategy = {
    showEditSaveState: (save: SaveState) => !!save?.matchData,
    showGenerate: (save: SaveState) => save?.currentQuest === CURRENT_QUEST_DONE,
  };

  constructor(
    private mobileUserService: MobileUserService,
    private matchService: MatchService,
    private dialog: DialogService<any, any>,
    private snackbar: NotificationService,
  ) {
    super();
  }

  onEditSaveState(save: SaveState) {
    this.dialog.open(SaveStateEditComponent, save);
    this.dialog.confirmed().subscribe((changes) => {
      if (!changes) return;
      this.mobileUserService.updateSaveState(save.user, save._id, changes).subscribe({
        next: (x) => this.snackbar.success('Nutzer aktualisiert'),
        error: () => this.snackbar.error('Nutzer konnte nicht aktualisiert werden'),
      });
    });
  }

  onGenerateCompanyMatches(save: SaveState) {
    this.showGenerateDialog().subscribe((confirmed) => {
      if (!confirmed) return;
      this.matchService.generateMatchesForUser(save.user, save.sessionRef).subscribe({
        next: (x) => this.snackbar.success('Matches generiert'),
        error: () => this.snackbar.error('Matches konnten nicht generiert werden'),
      });
    });
  }

  onGenerateGeneralMatches(save: SaveState) {
    this.showGenerateDialog().subscribe((confirmed) => {
      if (!confirmed) return;
      this.matchService.generateMatchesForUser(save.user, save.sessionRef, true).subscribe({
        next: (x) => this.snackbar.success('Allgemeine Matches generiert'),
        error: () => this.snackbar.error('Allgemeine Matches konnten nicht generiert werden'),
      });
    });
  }

  showGenerateDialog() {
    const data: ConfirmDialogData = {
      title: 'Matches generieren',
      message:
        'Sollen neue Matches für diesen Nutzer generiert werden? Der Nutzer muss im Anschluss die App neu starten.',
      cancelText: 'Abbrechen',
      confirmText: 'Generieren',
      confirmColor: 'warn',
    };
    this.dialog.open(ConfirmDialogComponent, data);
    return this.dialog.confirmed();
  }
}

export interface MobileUserMenuStrategy extends MenuStrategy {
  showEditSaveState(save: SaveState): boolean;
  showGenerate(save: SaveState): boolean;
}
