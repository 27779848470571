<mat-menu #contextMenu="matMenu">
  <ng-template let-item="target" matMenuContent>
    <ng-container>
      <button
        [matMenuTriggerData]="{ target: item }"
        [matMenuTriggerFor]="statusActions.contextMenu"
        *ngIf="strategy.showMove(item)"
        mat-menu-item
      >
        <mat-icon>arrow_forward</mat-icon>
        <span>{{ 'MENUS.MATCH.move' | translate }}</span>
      </button>
    </ng-container>
    <recrewt-match-move-menu
      (changeStatus)="changeStatus($event, item)"
      #statusActions
    ></recrewt-match-move-menu>
  </ng-template>
</mat-menu>
