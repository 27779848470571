<div [class.hover-effect]="!matStyle">
  <button
    #origin
    (click)="open(dropdown, origin); $event.stopPropagation()"
    (focus)="open(dropdown, origin)"
    class="mat-body-1 no-pad select-trigger"
    fxFlex="grow"
    type="button">
    <ng-template [ngIfElse]="searchTpl" [ngIf]="!isOpen">
      <ng-template [ngIf]="!originTpl">
        <ng-template [ngIfElse]="defaultOrigin" [ngIf]="matStyle">
          <mat-form-field [formGroup]="selectFormGroup" appearance="outline" fxFlex="grow">
            <mat-label *ngIf="placeholder">{{ placeholder | translate }}</mat-label>
            <mat-error>{{ error | translate }}</mat-error>
            <input
              [formControlName]="selectControlName"
              [required]="require"
              [value]="label | translate"
              class="mat-body-1"
              autocomplete="off"
              matInput
            />
          </mat-form-field>
        </ng-template>
        <ng-template #defaultOrigin>
          <p *ngIf="placeholder && !label" class="on-surface-medium no-pad p-1">{{ placeholder | translate }}</p>
          <p class="no-pad p-1 on-surface" *ngIf="!!label?.length">{{ label | translate }}</p>
        </ng-template>
      </ng-template>
      <ng-template *ngTemplateOutlet="originTpl"></ng-template>
    </ng-template>
    <ng-template #searchTpl>
      <ng-template [ngIfElse]="defaultSearch" [ngIf]="matStyle">
        <mat-form-field appearance="outline" fxFlex="grow">
          <mat-label *ngIf="placeholder">{{ placeholder | translate }}</mat-label>
          <input
            (click)="$event.stopPropagation()"
            [formControl]="searchControl"
            [required]="require"
            autocomplete="off"
            class="mat-body-1"
            matInput
            takeFocus
          />
        </mat-form-field>
      </ng-template>
      <ng-template #defaultSearch>
        <input
          (click)="$event.stopPropagation()"
          [formControl]="searchControl"
          [placeholder]="placeholder | translate"
          class="mat-styled-input mat-body-1"
          fxFlex="grow"
          takeFocus
          type="text"
        />
      </ng-template>
    </ng-template>
  </button>

  <ng-template #dropdown>
    <div class="select-menu mat-elevation-z3">
      <ng-container *ngIf="!(loading$ | async); else itemsLoading">
        <cdk-virtual-scroll-viewport [style.height.px]="visibleOptions * 48" class="select-menu-inner" itemSize="48">
          <div *ngIf="!options.length" class="no-results-item on-surface-medium">
            {{ "PLACEHOLDER.no_results" | translate }}
          </div>
          <div
            (click)="select(option);"
            *cdkVirtualFor="let option of options; index as i"
            [class.active]="isActive(option)"
            class="select-item"
            matRipple
          >
            <ng-template [ngIf]="!optionTpl">{{ displayValue(option) | translate }}</ng-template>
            <ng-template *ngTemplateOutlet="optionTpl; context: { $implicit: option, idx: i }"></ng-template>
          </div>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </div>
  </ng-template>
</div>

<ng-template #itemsLoading>
  <div style="height: 204px; padding-top: 4px">
    <ngx-skeleton-loader
      *ngFor="let i of [0, 1, 2, 3]"
      [theme]="{ width: '90%', height: '20px', margin: '12px 5%', padding: '0', backgroundColor: '#ebebeb' }"
    >
    </ngx-skeleton-loader>
  </div>
</ng-template>
