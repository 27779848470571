import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MobileUserState, MobileUserStore } from './mobile-user-store';
import { MobileUser } from '@data/mobile-user/mobile-user.model';

@Injectable({ providedIn: 'root' })
export class MobileUserQuery extends QueryEntity<MobileUserState, MobileUser> {
  constructor(protected store: MobileUserStore) {
    super(store);
  }
}
