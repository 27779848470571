import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TableCellDirective } from '@shared/directives/table-cell.directive';
import { EmptyConfig } from '@shared/components/empty-page/empty-page.component';
import { ArrayUtil } from '@shared/util/array.util';

@Component({
  selector: 'recrewt-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements AfterContentInit, OnChanges, AfterViewInit {
  @ContentChildren(TableCellDirective) tableCellDirectives?: QueryList<TableCellDirective>;

  _dataSource = new MatTableDataSource<T>();

  displayedColumns: string[] = [];

  cellTemplates: Map<string, TableCellDirective> = new Map();

  @ViewChild(MatSort) sort: MatSort | null = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  @Output() itemClick = new EventEmitter<T>();

  @Output() emptyClick = new EventEmitter();

  @Input() showHover = false;

  @Input() empty?: EmptyConfig;

  @Input() dataSource?: any;

  @Input() loading: boolean = false;

  @Input() private sortBy?: (sort: Sort, data: T[]) => T[];

  @Input() filter?: (item: any) => boolean = () => true;

  ngAfterContentInit(): void {
    this.displayedColumns =
      this.tableCellDirectives?.filter((it) => !!it?.cell).map((it) => it.cell!) ?? [];
    this.cellTemplates = ArrayUtil.toMap(
      this.tableCellDirectives?.toArray() ?? [],
      (val: { cell: any }) => val.cell,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataset = changes?.dataSource?.currentValue;
    if (!dataset) {
      return;
    }
    this._dataSource.data = dataset.filter(this.filter);
  }

  sortChanged(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      this._dataSource.data = this.dataSource.filter(this.filter);
      return;
    }

    if (this.sortBy) {
      this._dataSource.data = this.sortBy(sort, this._dataSource.data.slice());
    }
  }

  ngAfterViewInit(): void {
    this._dataSource.sort = this.sort;
    this._dataSource.paginator = this.paginator;
  }
}
