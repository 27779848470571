import { Pipe, PipeTransform } from '@angular/core';
import { MobileUserQuery } from '@data/mobile-user/mobile-user-query';
import { MobileUser } from '@data/mobile-user/mobile-user.model';

@Pipe({
  name: 'mobileUserRefToField',
})
export class MobileUserRefToFieldPipe implements PipeTransform {
  constructor(private users: MobileUserQuery) {}

  transform(
    value: string | undefined,
    field: keyof MobileUser,
    fallbackField?: keyof MobileUser,
  ): any {
    const user = this.users.getEntity(value);
    if (!user) {
      return undefined;
    }
    const data = user?.[field];
    if (!data && fallbackField) {
      return user?.[fallbackField];
    }
    return data;
  }
}
