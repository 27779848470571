import { Component } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { Match } from '@data/match/match.model';
import { MatchService } from '@data/match/match.service';
import { ValidationStatus } from '@core/constants/match/status';

@Component({
  selector: 'recrewt-match-menu',
  templateUrl: './match-menu.component.html',
  styleUrls: ['./match-menu.component.scss'],
})
export class MatchMenuComponent extends MenuComponent<Match> {
  strategy: MatchMenuStrategy = {
    showMove: () => true,
  };

  constructor(private matchService: MatchService) {
    super();
  }

  changeStatus(status: ValidationStatus, match: Match) {
    this.matchService.updateStatus(match._id.toString(), status).subscribe();
  }
}

export interface MatchMenuStrategy extends MenuStrategy {
  showMove(a: Match): boolean;
}
