import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ApplicationState, ApplicationStore } from './application.store';
import { Application } from './application.model';

@Injectable({ providedIn: 'root' })
export class ApplicationQuery extends QueryEntity<ApplicationState, Application> {
  constructor(protected store: ApplicationStore) {
    super(store);
  }
}
