import { Component, OnInit } from '@angular/core';
import { SignalService } from '@core/services/signal/signal.service';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { JobGeneralService } from '@data/job-general/job-general.service';

@Component({
  selector: 'recrewt-toolbar-layout',
  templateUrl: './toolbar-layout.component.html',
  styleUrls: ['./toolbar-layout.component.scss'],
})
export class ToolbarLayoutComponent implements OnInit {
  logoWithName = 'assets/images/RC_Logo_white.svg';

  useTest = false;

  constructor(
    public signalHandler: SignalService,
    public auth0: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private jobGeneralService: JobGeneralService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit(): void {
    const useTest = this.route.snapshot.queryParamMap.get('test');
    this.useTest = !!useTest;
    this.jobGeneralService.get();
  }

  toggleTestEnv(useTestEnv: boolean): void {
    resetStores({ exclude: ['user'] });
    const testEnvParams = useTestEnv ? { test: true } : {};
    this.refresh(testEnvParams);
  }

  private refresh(params: any) {
    this.router
      .navigate([], {
        relativeTo: this.route,
        queryParams: params,
      })
      .then();
  }
}
