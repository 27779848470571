import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import moment, { Moment } from 'moment';
import { ControlValueAccessor, FormBuilder, FormControl, NgControl } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'recrewt-unix-timestamp-picker',
  templateUrl: './unix-timestamp-picker.component.html',
  styleUrls: ['./unix-timestamp-picker.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: UnixTimestampPickerComponent,
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})
export class UnixTimestampPickerComponent
  implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy
{
  static nextId = 0;

  focused = false;

  touched = false;

  controlType = 'recrewt-unix-timestamp-picker';

  id = `${this.controlType}-${UnixTimestampPickerComponent.nextId++}`;

  dateControl = new FormControl();

  stateChanges = new Subject<void>();

  // eslint-disable-next-line
  @Input('aria-describedby') userAriaDescribedBy?: string;

  @ViewChild('input') dateInput!: HTMLInputElement;

  constructor(
    private _formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _placeholder!: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.disabled ? this.dateControl.disable() : this.dateControl.enable();
    this.stateChanges.next();
  }

  @Input()
  get value(): number | null {
    if (this.dateControl.valid) {
      const value: Moment = this.dateControl.value;
      const unix = value?.utc().unix();
      return unix ? unix * 1000 : null;
    }
    return null;
  }

  set value(value: number | null) {
    this.dateControl.setValue(value ? this.timestampToMoment(value) : null);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.dateControl.invalid && this.touched;
  }

  get empty() {
    const value = this.dateControl.value;
    return !value;
  }

  get shouldLabelFloat() {
    return true;
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  timestampToMoment(millis: number): Moment {
    return moment(millis);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.dateInput, 'program');
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.recrewt-unix-timestamp-picker',
    )!;
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  writeValue(millis: number | null) {
    this.value = millis;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
