import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupComponent } from '@shared/components/popup/base/popup.component';
import { get } from 'lodash';

@UntilDestroy()
@Component({
  template: '',
})
export class SearchPopupHandlerComponent extends PopupComponent implements OnInit, OnDestroy {
  @Input() multi = false;

  @Input() labelKey?: string;

  @Input() idKey = 'id';

  @Input() loading$?: Observable<boolean>;

  @Input() onRemove?: Observable<any>;

  @Input() options$?: Observable<any[]>;

  @Input() options: any[] = [];

  @Input() model: any;

  @Input() optionTpl?: TemplateRef<any>;

  @Input() originTpl?: TemplateRef<any>;

  @Input() mapValueTo?: (value: any) => any;

  @Input() displayWith?: (value: any) => string;

  @Output() selectChange = new EventEmitter();

  searchControl = new UntypedFormControl();

  visibleOptions = 4;

  protected originalOptions: any[] = [];

  constructor(
    vcr: ViewContainerRef,
    zone: NgZone,
    cdr: ChangeDetectorRef,
    protected translate: TranslateService,
  ) {
    super(vcr, zone, cdr);
  }

  get label(): any {
    if (!this.multi) {
      return this.displayValue(this.model);
    }
  }

  ngOnInit(): void {
    if (this.multi && !this.model) {
      if (!this.model) {
        this.model = [];
      } else {
        this.initMultiModel();
      }
    } else if (!this.multi && !!this.model) {
      this.initModel();
    }

    this.onRemove?.pipe(untilDestroyed(this)).subscribe((removeId) => this.remove(removeId));

    if (this.options$) {
      this.options$.pipe(untilDestroyed(this)).subscribe((options) => {
        this.options = options;
        this.originalOptions = Object.assign(options, []);
        requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));
      });
    } else {
      this.originalOptions = [...this.options];
      requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));
    }

    this.searchControl.valueChanges
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe((term) => this.search(term));
  }

  search(value: string): void {
    this.options = this.originalOptions.filter((option) => {
      const terms = value.trim().split(' ') ?? [];
      return terms.every((it) =>
        this.translate
          .instant(this.displayValue(option))
          .toLowerCase()
          .includes(it.toLowerCase().trim()),
      );
    });
    if (this.multi) {
      this.options = this.options.filter((v) => !this.model.includes(v));
    }
    requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));
  }

  displayValue(option: any): any {
    if (!this.labelKey) {
      return option;
    }
    return option ? get(option, this.labelKey) : option;
  }

  optionValue(option: any): any {
    if (!this.mapValueTo) {
      return option;
    }
    return option ? this.mapValueTo(option) : option;
  }

  optionId(option: any): any {
    if (!this.idKey) {
      return option;
    }
    return option ? option[this.idKey] : option;
  }

  close() {
    super.close();
    this.searchControl.patchValue('');
  }

  select(option: any): void {
    if (this.multi) {
      if (!this.model.map((v: any) => this.optionValue(v)).includes(this.optionValue(option))) {
        this.model.push(option);
        this.selectChange.emit(this.optionValue(option));
        this.onChanged(this.optionValue(option));
      }
    } else {
      if (this.optionValue(option) !== this.optionValue(this.model)) {
        this.model = option;
        this.selectChange.emit(this.optionValue(option));
        this.onChanged(this.optionValue(option));
      }
    }
    this.close();
  }

  isActive(option: any): boolean {
    if (!this.model) {
      return false;
    }
    if (this.multi) {
      return this.model.map((v: any) => this.optionId(v)).includes(this.optionId(option));
    } else {
      return this.optionId(option) === this.optionId(this.model);
    }
  }

  open(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
    if (this.multi) {
      this.options = this.originalOptions.filter((v) => !this.model.includes(v));
    }
    requestAnimationFrame(() => (this.visibleOptions = this.options.length || 1));
    super.open(dropdownTpl, origin);
  }

  writeValue(obj: any): void {
    if (this.displayWith) {
      this.model = this.displayWith(obj);
    } else {
      this.model = obj;
    }
  }

  private initModel(): void {
    this.model = this.options.find((currentOption) =>
      this.idKey
        ? currentOption[this.idKey] === this.optionId(this.model)
        : currentOption === this.model,
    );
  }

  private initMultiModel(): void {
    this.model = this.options.filter((currentOption) =>
      this.idKey
        ? currentOption[this.idKey] === this.optionId(this.model)
        : currentOption === this.model,
    );
  }

  private remove(removeId: any): void {
    if (!this.multi) {
      return;
    }
    this.model = this.model.filter((v: any) => this.optionId(v) !== removeId);
  }
}
