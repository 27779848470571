export enum ValidationStatus {
  NEW,
  IN_REVIEW,
  USER_ERROR,
  ALGO_ERROR,
  OTHER,
  OK,
}

export enum ApplicationValidationStatus {
  PUBLISHED = 0,
  NEW = -1,
  IN_PROGRESS = -2,
  REVIEW = -3,
  IN_REVIEW = -4,
  FAULTY = -5,
}

export const APPLICATION_NEW = -1;
export const APPLICATION_IN_PROGRESS = -2;
export const APPLICATION_REVIEW = -3;
export const APPLICATION_IN_REVIEW = -4;
export const APPLICATION_B_WARE = -5;

export const APPLICATION_VALIDATION_STATUS_KEYS = [
  'new',
  'in_progress',
  'review',
  'in_review',
  'published',
  'faulty',
];

export const VALIDATION_STATUS_KEYS = [
  'new',
  'in_review',
  'user_error',
  'algo_error',
  'other',
  'ok',
];
