import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MatchState, MatchStore } from './match.store';
import { Match } from './match.model';

@Injectable({ providedIn: 'root' })
export class MatchQuery extends QueryEntity<MatchState, Match> {
  constructor(protected store: MatchStore) {
    super(store);
  }
}
