import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MobileUser, SaveState } from '@data/mobile-user/mobile-user.model';
import { MobileUserStore } from '@data/mobile-user/mobile-user-store';
import { DataBaseService } from '@data/data.base.service';
import { MobileUserQuery } from '@data/mobile-user/mobile-user-query';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MobileUserService extends DataBaseService<MobileUser> {
  constructor(
    mobileUserStore: MobileUserStore,
    mobileUserQuery: MobileUserQuery,
    http: HttpClient,
  ) {
    super(http, mobileUserStore, mobileUserQuery, 'mobile-users');
  }

  updateSaveState(id: string, saveStateId: string, changes: Partial<SaveState>) {
    return this.http.put(`${this.url}/${id}/save-states/${saveStateId}`, changes).pipe(
      tap(() =>
        this.store.update(id, (entity) => {
          let indexToUpdate = entity.saveStates.findIndex((s) => s._id === saveStateId);
          const saves = [...entity.saveStates];
          saves[indexToUpdate] = { ...saves[indexToUpdate], ...changes };
          return { ...entity, saveStates: saves };
        }),
      ),
    );
  }
}
