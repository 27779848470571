import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface EmptyConfig {
  icon?: string | 'recrewt';
  headline?: string;
  text?: string;
  buttonIcon?: string;
  buttonText?: string;
}

const DEFAULT_CONFIG: EmptyConfig = {
  icon: 'info',
};

@Component({
  selector: 'recrewt-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss'],
})
export class EmptyPageComponent {
  @Input() config?: EmptyConfig = DEFAULT_CONFIG;

  @Output() buttonClick = new EventEmitter();
}
