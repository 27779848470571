import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EntityState, EntityStore, QueryEntity } from '@datorama/akita';
import { environment } from '../../environments/environment';
import { map, take, tap } from 'rxjs/operators';
import { Identifiable } from '@shared/components/dnd-table/column-config';

@Injectable({
  providedIn: 'root',
})
export class DataBaseService<T extends Identifiable> {
  protected url?: string;

  constructor(
    protected http: HttpClient,
    protected store: EntityStore<EntityState<T>, T>,
    protected query: QueryEntity<EntityState<T>, T>,
    protected route: string,
  ) {
    this.refreshUrl();
  }

  getById(_id: string, params?: any): Observable<T | undefined> {
    this.refreshUrl();
    const item = this.query.getEntity(_id);
    if (item) {
      return of(item).pipe(take(1));
    }
    const httpParams = new HttpParams({ fromObject: { _id, ...params } });
    return this.http.get<T[] | undefined>(`${this.url}`, { params: httpParams }).pipe(
      map((it) => it?.[0]),
      tap((it) => {
        this.store.setLoading(false);
        if (it) this.store.upsert(it._id, it);
      }),
    );
  }

  get(params?: any, useCache = true): void {
    this.refreshUrl();
    const httpParams = new HttpParams({ fromObject: params });
    if (!useCache || !this.query.getHasCache()) {
      this.http.get<T[]>(`${this.url}`, { params: httpParams }).subscribe((it) => {
        this.store.set(it ?? []);
      });
    }
  }

  paginated(skip: number, limit: number, params?: any): void {
    this.refreshUrl();
    this.store.setLoading(true);
    const httpParams = new HttpParams({ fromObject: params });
    this.http
      .get<T[]>(`${this.url}/paginated/${skip}/${limit}`, { params: httpParams })
      .subscribe((it) => {
        this.store.set(it ?? []);
      });
  }

  count(typeId: number | null): Observable<number> {
    this.refreshUrl();
    return this.http.get<number>(`${this.url}/count?param=${typeId}`);
  }

  private refreshUrl() {
    this.url = `${environment.apiUrl()}/${this.route}`;
  }
}
