import { Component, Input } from '@angular/core';
import { FilterOption } from '@data/filter/filter';

@Component({
  selector: 'recrewt-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss'],
})
export class FilterOptionComponent {
  @Input() filterOption?: FilterOption<any, any>;
}
