import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Match } from './match.model';

export interface MatchState extends EntityState<Match> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'match', resettable: true, idKey: '_id' })
export class MatchStore extends EntityStore<MatchState, Match> {
  constructor() {
    super();
    this.createUIStore();
  }

  akitaPreAddEntity(newEntity: Match): Match {
    if (newEntity.creationTime) {
      newEntity.creationTime = new Date(newEntity.creationTime);
    }
    return super.akitaPreAddEntity(newEntity);
  }
}
