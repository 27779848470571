import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Confirmation } from '@shared/components/dialog/confirm/confirm-dialog.component';
import { SaveState } from '@data/mobile-user/mobile-user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EDUCATION_LEVEL } from '@core/constants/job/education';

@Component({
  selector: 'recrewt-save-state-edit',
  templateUrl: './save-state-edit.component.html',
  styleUrls: ['./save-state-edit.component.scss'],
})
export class SaveStateEditComponent implements OnInit {
  form!: FormGroup;

  readonly EDUCATION_LEVEL = EDUCATION_LEVEL;

  constructor(
    public dialogRef: MatDialogRef<SaveStateEditComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: SaveState,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.form = this.fb.group({
      matchData: this.fb.group({
        general: this.fb.group({
          searchRadius: [this.data.matchData.general.searchRadius, [Validators.required]],
          start: [
            this.data.matchData.general.start,
            [Validators.required, Validators.pattern(/^20\d{2}$/)],
          ],
          educationLevel: [this.data.matchData.general.educationLevel, [Validators.required]],
        }),
      }),
    });
  }

  noActionClicked(): void {
    this.dialogRef.close(Confirmation.CANCEL);
  }

  onEditSaveState(): void {
    if (!this.form.valid) return;

    const changes = {
      matchData: {
        additional: this.data.matchData.additional,
        frame: this.data.matchData.frame,
        characteristics: this.data.matchData.characteristics,
        general: { ...this.data.matchData.general, ...this.form.value.matchData.general },
      },
    };
    this.dialogRef.close(changes);
  }
}
