<h1 mat-dialog-title>{{ 'USERS.SAVE_STATE.EDIT.headline' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="form" fxLayout="column" fxLayoutGap="24px">
    <div formGroupName="matchData">
      <div formGroupName="general">
        <div>
          <h3 class="width-match-parent">
            {{ 'USERS.SAVE_STATE.EDIT.radius' | translate }}
          </h3>
          <mat-slider
            class="width-match-parent"
            formControlName="searchRadius"
            max="100"
            min="0"
            thumbLabel
          ></mat-slider>
        </div>
        <div fxLayout="row" fxLayoutGap="24px">
          <div fxFlex="grow" fxLayout="column">
            <h3 class="width-match-parent">
              {{ 'USERS.SAVE_STATE.EDIT.start' | translate }}
            </h3>
            <mat-form-field appearance="outline" class="width-match-parent">
              <input formControlName="start" matInput type="number" />
              <mat-label>{{ 'USERS.SAVE_STATE.EDIT.start' | translate }}</mat-label>
            </mat-form-field>
          </div>
          <div fxFlex="grow" fxLayout="column">
            <h3 class="width-match-parent">
              {{ 'USERS.SAVE_STATE.EDIT.education' | translate }}
            </h3>
            <mat-form-field appearance="outline" class="width-match-parent">
              <mat-select formControlName="educationLevel">
                <mat-option *ngFor="let education of EDUCATION_LEVEL; index as i" [value]="i">
                  {{ education | translate }}
                </mat-option>
              </mat-select>
              <mat-label>{{ 'USERS.SAVE_STATE.EDIT.education' | translate }}</mat-label>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="noActionClicked()" mat-button>
    {{ 'COMMON.cancel' | translate }}
  </button>
  <button (click)="onEditSaveState()" color="accent" mat-flat-button>
    {{ 'COMMON.save' | translate }}
  </button>
</div>
