<mat-menu #statusActions="matMenu">
  <ng-template let-item="target" matMenuContent>
    <ng-container *ngFor="let status of VALIDATION_STATUS_KEYS | slice: 2">
      <button
        (click)="changeStatus.emit(ApplicationValidationStatus[status])"
        [disabled]="!strategy.showStatus(ApplicationValidationStatus[status], item)"
        mat-menu-item
      >
        <span>{{ 'APPLICATIONS.TABLE.' + status.toLocaleLowerCase() | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
