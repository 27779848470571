import { Component, EventEmitter, Output } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { Application } from '@data/application/application.model';
import { ApplicationService } from '@data/application/application.service';
import { ApplicationValidationStatus } from '@core/constants/match/status';
import { ApplicationStore } from '@data/application/application.store';

@Component({
  selector: 'recrewt-application-menu',
  templateUrl: './application-menu.component.html',
  styleUrls: ['./application-menu.component.scss'],
})
export class ApplicationMenuComponent extends MenuComponent<Application> {
  @Output() publish = new EventEmitter<Application>();

  strategy: ApplicationMenuStrategy = {
    showMove: (application) => application.status < ApplicationValidationStatus.PUBLISHED,
    showPublish: (application) => application.status < ApplicationValidationStatus.PUBLISHED,
  };

  constructor(
    private applicationService: ApplicationService,
    private applicationStore: ApplicationStore,
  ) {
    super();
  }

  publishApplication(application: Application) {
    this.publish.emit(application);
  }

  changeStatus(status: ApplicationValidationStatus, application: Application) {
    this.applicationService
      .updateStatus(application._id, status)
      .subscribe(this.applicationStore.update(application._id, { status }));
  }
}

export interface ApplicationMenuStrategy extends MenuStrategy {
  showMove(a: Application): boolean;
  showPublish(a: Application): boolean;
}
