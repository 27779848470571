<div class="height-match-parent">
  <mat-toolbar class="no-select mat-elevation-z2">
    <button
      (click)="signalHandler.notifySignalListener('toggle_sidenav')"
      aria-label="Example icon-button with menu icon"
      class="tint-white"
      mat-icon-button
    >
      <mat-icon>menu</mat-icon>
    </button>
    <div fxFlex="grow" fxLayoutAlign="space-between center" style="margin-right: 16px">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <a queryParamsHandling="merge" routerLink="company">
          <img [src]="logoWithName" alt="Recrewt Logo" class="nav-home-logo m-l-1" />
        </a>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <mat-slide-toggle
          (change)="toggleTestEnv($event.checked)"
          [checked]="useTest"
          class="mat-subheading-2 no-pad m-r-2"
          ><span class="tint-white"> {{ 'Testumgebung' }}</span></mat-slide-toggle
        >
        <button
          (click)="auth0.logout()"
          aria-label="Example icon-button with logout icon"
          class="tint-white"
          mat-icon-button
        >
          <mat-icon>logout</mat-icon>
        </button>
      </div>
    </div>
  </mat-toolbar>
  <div class="toolbar-content">
    <router-outlet></router-outlet>
  </div>
</div>
