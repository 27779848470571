import { COLOR_CONFIGS } from '@core/constants/status-colors';
import { MatchStatus } from '@core/enums/match-status';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';

export const MATCH_STATUS_TAGS = {
  new: {
    ...COLOR_CONFIGS.INFO,
    icon: 'auto_awesome',
    text: 'COMMON.new',
  },
  like: {
    ...COLOR_CONFIGS.WARN,
    icon: 'thumb_up',
    text: 'event.2_like',
  },
  highlike: {
    ...COLOR_CONFIGS.OK,
    icon: 'favorite',
    text: 'event.3_super_like',
  },
  application: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'badge',
    text: 'event.5_application',
  },
  dislike: {
    ...COLOR_CONFIGS.WARN,
    icon: 'thumb_down',
    text: 'event.4_dislike',
  },
  trash: {
    ...COLOR_CONFIGS.OK,
    icon: 'delete',
    text: 'event.6_trash',
  },
  match: {
    ...COLOR_CONFIGS.INFO,
    icon: 'join_inner',
    text: 'event.1_match',
  },
};

export function getMatchStatusTagConfig(status: MatchStatus): StatusTagConfig | undefined {
  switch (status) {
    case MatchStatus.NEW:
      return MATCH_STATUS_TAGS.new;
    case MatchStatus.MATCH:
      return MATCH_STATUS_TAGS.match;
    case MatchStatus.LIKE:
      return MATCH_STATUS_TAGS.like;
    case MatchStatus.HIGH_LIKE:
      return MATCH_STATUS_TAGS.highlike;
    case MatchStatus.APPLICATION:
      return MATCH_STATUS_TAGS.application;
    case MatchStatus.DISLIKE:
      return MATCH_STATUS_TAGS.dislike;
    case MatchStatus.TRASH:
      return MATCH_STATUS_TAGS.trash;
    default:
      return undefined;
  }
}
