import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { JobState, JobStore } from './job.store';
import { Job } from '@data/job/job.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class JobQuery extends QueryEntity<JobState, Job> {
  constructor(protected store: JobStore) {
    super(store);
  }
}
