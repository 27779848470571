import { Component, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';

@Component({
  template: '',
})
export class MenuComponent<T> {
  strategy?: MenuStrategy;

  @ViewChild(MatMenuTrigger, { static: true }) menu?: MatMenuTrigger;

  @ViewChild(MatMenu, { static: true }) contextMenu?: MatMenu;

  contextMenuPosition = { x: '0px', y: '0px' };

  onContextMenu(event: MouseEvent, target: T, strategy: MenuStrategy): void {
    if (!this.menu) return;
    event.preventDefault();
    this.strategy = strategy;
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.menu.menuData = { target };
    this.menu.menu?.focusFirstItem('mouse');
    this.menu.openMenu();
  }
}

export interface MenuStrategy {}
