<div style="display: block; min-height: 270px">
  <canvas
    [type]="lineChartType"
    [options]="lineChartOptions"
    [datasets]="data | translateList: 'label'"
    [labels]="labels | translateList"
    [legend]="lineChartLegend"
    [plugins]="lineChartPlugins"
    baseChart
    class="height-match-parent"
    fxFlex="100%"
    height="275px"
  >
  </canvas>
</div>
