import { Identifiable } from '@shared/components/dnd-table/column-config';

export const CURRENT_QUEST_DONE = 26;

export interface SaveState {
  _id: string;
  matchData: UserMatchData;

  name: string;
  sessionRef?: string;
  user: string;
  currentQuest?: number;
}

export interface MobileUser extends Identifiable {
  birthDate: number;
  birthLocation: string;
  contact: UserContactData;
  email: string;
  cv: [CvItem];
  gender: string;
  currentQuest: number;
  location: MongoLocation;
  saveStates: SaveState[];
  selectedSaveStateRef?: string;
  name: string;
  settings: number;
  hasNewMatches?: boolean;
  hasAnyMatches?: boolean;
  latestMatchTime?: number;
  storyProgress?: number;
  firstName?: string;
  lastName?: string;
}

export interface CvItem {
  data: CvItemEntry[];
  id: string;
  typeId: number;
}

export interface CvItemEntry {
  aRef: string;
  desc: string;
  from: string;
  gDate: string;
  grade: string;
  loc: string;
  name: string;
  to: string;
  type: string;
}

interface UserContactData {
  instagram: string;
  linkedin: string;
  mail: string;
  phone: string;
  twitter: string;
}

interface MongoLocation {
  location: {
    type: string;
    coordinates: number[];
  };
  address: string;
  region: string;
}

export interface UserMatchData {
  additional: UserMatchDataAdditional;
  characteristics: number;
  frame: number;
  general: UserMatchDataGeneral;
}

interface UserMatchDataAdditional {
  abortReasons: string[];
  orientation: string[];
}

interface UserMatchDataGeneral {
  abortReasons: number;
  finishedTrainings: number;
  likedBranches: number;
  likedJobs: number;
  likedScholar: number;
  knownJobs: number;
  knownScholar: number;
  mobilityLevel: number;
  educationLevel: number;
  orientation: number;
  progress: number;
  start: number;
  searchRadius: number;
  finishedScholar: number;
  finishedStudies: number;
  abortedTrainings: number;
  abortedScholar: number;
  abortedStudies: number;
  interested: number;
  highlyInterested: number;
  uninterested: number;
  highlyUninterested: number;
  noGos: number;
}
