import { ApplicationStage } from '@data/application/application.model';
import { ApplicationValidationStatus } from '@core/constants/match/status';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';
import { COLOR_CONFIGS } from '@core/constants/status-colors';

export type ApplicationStatusType =
  | 'new'
  | 'in_progress'
  | 'in_review'
  | 'review'
  | 'faulty'
  | 'published'
  | 'invited'
  | 'assessment'
  | 'offer'
  | 'rejected'
  | 'archived'
  | 'rejected_by_user'
  | 'accepted_by_user';

export const APPLICATION_STATUS_TAGS = {
  published: {
    ...COLOR_CONFIGS.OK,
    icon: 'check',
    text: 'APPLICATIONS.STATUS.published',
  },
  faulty: {
    ...COLOR_CONFIGS.ERROR,
    icon: 'close',
    text: 'APPLICATIONS.STATUS.faulty',
  },
  in_progress: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'settings_suggest',
    text: 'APPLICATIONS.STATUS.in_progress',
  },
  review: {
    ...COLOR_CONFIGS.WARN,
    icon: 'remove_red_eye',
    text: 'APPLICATIONS.STATUS.review',
  },
  in_review: {
    ...COLOR_CONFIGS.WARN,
    icon: 'rate_review',
    text: 'APPLICATIONS.STATUS.in_review',
  },
  new: {
    ...COLOR_CONFIGS.INFO,
    icon: 'auto_awesome',
    text: 'APPLICATIONS.STATUS.new',
  },
  invited: {
    ...COLOR_CONFIGS.WARN,
    icon: 'insert_invitation',
    text: 'APPLICATIONS.STATUS.invited',
  },
  assessment: {
    ...COLOR_CONFIGS.WARN,
    icon: 'insert_invitation',
    text: 'APPLICATIONS.STATUS.assessment',
  },
  offer: {
    ...COLOR_CONFIGS.OK,
    icon: 'insert_invitation',
    text: 'APPLICATIONS.STATUS.offer',
  },
  rejected: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'close',
    text: 'APPLICATIONS.STATUS.rejected',
  },
  archived: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'archive',
    text: 'APPLICATIONS.STATUS.archived',
  },
  rejected_by_user: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'not_interested',
    text: 'APPLICATIONS.STATUS.rejected_by_user',
  },
  accepted_by_user: {
    ...COLOR_CONFIGS.OK,
    icon: 'check',
    text: 'APPLICATIONS.STATUS.accepted_by_user',
  },
};

const EXPRESS_STATUS_TAG = {
  ...COLOR_CONFIGS.INFO,
  icon: 'bolt',
  text: 'APPLICATIONS.TABLE.COLUMN.express',
};

const APPRENTICESHIP_STATUS_TAG = {
  ...COLOR_CONFIGS.INFO,
  text: 'APPLICATIONS.TABLE.COLUMN.apprenticeship',
};

export function getApplicationStatus(
  status: ApplicationValidationStatus,
  stage: ApplicationStage,
): ApplicationStatusType | undefined {
  if (status > ApplicationValidationStatus.NEW) {
    switch (stage) {
      case ApplicationStage.INTERVIEW:
        return 'invited';
      case ApplicationStage.ASSESSMENT:
        return 'assessment';
      case ApplicationStage.OFFER:
        return 'offer';
      case ApplicationStage.REJECTED:
        return 'rejected';
      case ApplicationStage.REJECTED_BY_USER:
        return 'rejected_by_user';
      case ApplicationStage.ACCEPTED_BY_USER:
        return 'accepted_by_user';
      default:
        return 'published';
    }
  }
  switch (status) {
    case ApplicationValidationStatus.NEW:
      return 'new';
    case ApplicationValidationStatus.IN_PROGRESS:
      return 'in_progress';
    case ApplicationValidationStatus.IN_REVIEW:
      return 'in_review';
    case ApplicationValidationStatus.REVIEW:
      return 'review';
    case ApplicationValidationStatus.FAULTY:
      return 'faulty';
  }
}

export function getApplicationStatusTag(
  status: ApplicationValidationStatus,
  stage: ApplicationStage,
) {
  const applicationStatus = getApplicationStatus(status, stage);
  if (!applicationStatus) return undefined;
  return APPLICATION_STATUS_TAGS[applicationStatus] ?? undefined;
}

export function getExpressStatusTag(isExpress: boolean): StatusTagConfig | undefined {
  if (isExpress) {
    return EXPRESS_STATUS_TAG;
  }
}

export function getApprenticeshipStatusTag(isApprenticeship: boolean): StatusTagConfig | undefined {
  if (isApprenticeship) {
    return APPRENTICESHIP_STATUS_TAG;
  }
}
