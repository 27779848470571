<div
  [style.backgroundColor]="config?.backgroundColor"
  [style.color]="config?.color"
  class="tag"
  fxLayoutAlign="center center"
>
  <span
    #tooltip="matTooltip"
    [matTooltipDisabled]="!config?.desc"
    [matTooltip]="config?.desc | translate"
    class="mat-caption no-pad"
    matTooltipClass="tooltip"
    matTooltipPosition="above"
    matTooltipShowDelay="500"
  >
    {{ config?.text | translate }}
  </span>
  <mat-icon *ngIf="config?.icon" [style.color]="config?.color" class="icon">{{
    config?.icon
  }}</mat-icon>
</div>
