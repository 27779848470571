<mat-menu #statusActions="matMenu">
  <ng-template let-item="target" matMenuContent>
    <ng-container *ngFor="let status of VALIDATION_STATUS_KEYS">
      <button
        (click)="changeStatus.emit(ValidationStatus[status])"
        *ngIf="strategy.showStatus(ValidationStatus[status], item)"
        mat-menu-item
      >
        <span>{{ 'MATCHING.TABLE.' + status.toLocaleLowerCase() | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
