import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Filter } from '@data/filter/filter';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'recrewt-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent implements OnInit {
  @Input() filters: Filter<any, any>[] = [];

  @Output() apply = new EventEmitter<void>();

  filterForm?: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.filterForm = this.fb.group({ filters: this.fb.array([]) });
  }

  get filtersArray(): UntypedFormArray | undefined {
    return this.filterForm?.get('filters') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.generateFilterGroup();
  }

  onSubmit(): void {
    this.filterForm?.markAllAsTouched();
    const { valid } = this.filterForm!;
    if (!valid) return;
    this.apply.emit();
  }

  filterArrayValidator(filter: Filter<any, any>): ValidatorFn {
    return (control) => {
      if (filter?.isActive) {
        return control.value ? null : { missing: true };
      }
      return null;
    };
  }

  deactivateAll(): void {
    for (const filter of this.filters) {
      filter.isActive = false;
      filter.value = null;
    }
  }

  private generateFilterGroup() {
    for (let i = 0; i < this.filters.length; i++) {
      const filter = this.filters[i];
      let control;
      if (filter.type === 'list') {
        control = this.createListFilterControl(filter);
      } else {
        control = this.createValueFilterControl(filter);
      }
      this.filtersArray?.push(control);
    }
  }

  private createListFilterControl(filter: Filter<any, any>) {
    const control = this.fb.array([]);
    for (const opt of filter.options) {
      control.push(this.fb.control(opt.isActive));
    }
    return control;
  }

  private createValueFilterControl(filter: Filter<any, any>) {
    const control = this.fb.control(filter.value);
    control.setValidators([this.filterArrayValidator(filter)]);
    return control;
  }
}
