<div class="example-container">
  <div
    cdkDropList
    [cdkDropListData]="items"
    id="review"
    fxLayout="column"
    class="example-list"
    (cdkDropListDropped)="drop($event)">
    <header>
      <p class="mat-subheading-1 p-l-1 p-t-1 p-b-1">{{ headline | translate | uppercase }} {{' ' + items.length}}
        ITEMS</p>
    </header>
    <div class="items" fxLayout="column" fxLayoutGap="4px" *ngIf="items.length ; else emptyTpl">
      <ng-container>
        <div (click)="itemClicked.emit(item)" *ngFor="let item of items" cdkDrag>
          <ng-template *ngTemplateOutlet="itemTpl; context: { $implicit: item }"></ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #emptyTpl>
  <p class="text-align-center">{{'MATCHING.none' | translate }}</p>
</ng-template>
