<form (ngSubmit)="onSubmit()" [formGroup]="filterForm">
  <header class="p-1 light-bg light-bottom-border" fxLayoutAlign="space-between center">
    <button (click)="deactivateAll()" mat-raised-button color="white" type="reset">
      {{ 'COMMON.delete' | translate }}
    </button>
    <span class="mat-subheading-1 no-pad">{{ 'FILTER.filter' | translate }}</span>
    <button color="accent" mat-raised-button type="submit">
      {{ 'COMMON.done' | translate }}
    </button>
  </header>
  <recrewt-filter
    *ngFor="let filter of filters; index as i"
    [filter]="filter"
    [frmControlName]="i"
    [frmGroup]="filterForm"
    frmArrayName="filters"
  ></recrewt-filter>
</form>
