export const BRANCHES = [
  'MAPPING.BRANCHES.Agriculture',
  'MAPPING.BRANCHES.Construction',
  'MAPPING.BRANCHES.Chemistry',
  'MAPPING.BRANCHES.Manufacturing',
  'MAPPING.BRANCHES.ECommerce',
  'MAPPING.BRANCHES.Energy',
  'MAPPING.BRANCHES.Finance',
  'MAPPING.BRANCHES.Society',
  'MAPPING.BRANCHES.Culture',
  'MAPPING.BRANCHES.Trading',
  'MAPPING.BRANCHES.Social',
  'MAPPING.BRANCHES.Electrics',
  'MAPPING.BRANCHES.Mechanics',
  'MAPPING.BRANCHES.Health',
  'MAPPING.BRANCHES.Fitness',
  'MAPPING.BRANCHES.Technic',
  'MAPPING.BRANCHES.Tourism',
  'MAPPING.BRANCHES.Logistics',
  'MAPPING.BRANCHES.Marketing',
  'MAPPING.BRANCHES.Economy',
  'MAPPING.BRANCHES.IT',
  'MAPPING.BRANCHES.Fashion',
  'MAPPING.BRANCHES.Media',
  'MAPPING.BRANCHES.Food',
  'MAPPING.BRANCHES.Legal',
  'MAPPING.BRANCHES.Flight',
];
