import { Identifiable } from '@shared/components/dnd-table/column-config';

export interface Application extends Identifiable {
  _id: string;
  jobRef: string;
  userId: string;
  userName: string;
  created: Date;
  internStart?: number;
  internEnd?: number;
  isInternship: boolean;
  isExpress?: boolean;
  hasDocument?: boolean;
  status: number;
  currentStage: ApplicationStage;
  currentStageDate?: Date;
  schoolName?: string;
  interviewAnswers: string[];
  attachedDocuments?: string[];
}

export interface ApplicationData {
  applId: string;
  contactEmail: string;
  assigneeId: string;
  companyId: string;
  jobId: string;
  jobName: string;
  userEmail: string;
  userId: string;
  userName: string;
  isInternship: boolean;
  attachedDocuments?: string[];
}

export enum ApplicationStage {
  DEFAULT,
  IN_EDIT,
  SENT,
  REVIEW,
  INTERVIEW,
  ASSESSMENT,
  OFFER,
  REJECTED,
  REJECTED_BY_USER,
  ACCEPTED_BY_USER,
}
