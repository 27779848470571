import { BRANCHES } from '@core/constants/job/branches';
import { REQUIREMENTS } from '@core/constants/job/requirements';
import { COMPANY_SIZE } from '@core/constants/company/size';
import { EDUCATION_LEVEL } from '@core/constants/job/education';

export class MapperUtil {
  public static decToStr(value: number, mapping: CardinalMapping): string | null {
    if (value === null || value === undefined) {
      return null;
    }
    switch (mapping) {
      case 'education':
        return MapperUtil.mapDecimal(value, EDUCATION_LEVEL);
      case 'branch':
        return MapperUtil.mapDecimal(value, BRANCHES);
      case 'company-size':
        return MapperUtil.mapDecimal(value, COMPANY_SIZE);
      case 'requirements':
        return MapperUtil.mapDecimal(value, REQUIREMENTS);
    }
    return null;
  }

  public static mapBinary(value: number, dataSource: any[]): any[] {
    if (!value) {
      return [];
    }
    const values = [];
    let index = 0;
    while (value != 0 && index < dataSource.length) {
      if (value % 2 !== 0) {
        values.push(dataSource[index]);
      }
      index++;
      value >>= 1;
    }
    return values;
  }

  public static mapDecimal(value: number, dataSource: string[]): string {
    if (value < 0 || value >= dataSource.length) {
      throw new Error('Invalid Mapping');
    }
    return dataSource[value];
  }
}

export type CardinalMapping =
  | 'education'
  | 'requirements'
  | 'branch'
  | 'company-size'
  | 'work-model';
export type ArrayMapping = 'requirements' | 'qualifications' | 'perks' | 'topics';
