import { Pipe, PipeTransform } from '@angular/core';
import { JobQuery } from '@data/job/job.query';
import { Job } from '@data/job/job.model';

@Pipe({
  name: 'jobRefToField',
})
export class JobRefToFieldPipe implements PipeTransform {
  constructor(private jobs: JobQuery) {}

  transform(value: string, field: keyof Job, fallbackField?: keyof Job): any {
    const job = this.jobs.getEntity(value);
    if (!job) {
      return undefined;
    }
    const data = job?.[field];
    if (!data && fallbackField) {
      return job?.[fallbackField];
    }
    return data;
  }
}
