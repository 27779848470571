<mat-menu #contextMenu="matMenu">
  <ng-template let-item="target" matMenuContent>
    <button
      (click)="publishApplication(item)"
      [disabled]="!strategy.showPublish(item)"
      mat-menu-item
    >
      <mat-icon>check</mat-icon>
      <span>{{ 'MENUS.APPLICATION.publish' | translate }}</span>
    </button>

    <button
      [matMenuTriggerData]="{ target: item }"
      [matMenuTriggerFor]="statusActions.contextMenu"
      [disabled]="!strategy.showMove(item)"
      mat-menu-item
    >
      <mat-icon>arrow_forward</mat-icon>
      <span>{{ 'MENUS.APPLICATION.move' | translate }}</span>
    </button>
    <recrewt-application-move-menu
      (changeStatus)="changeStatus($event, item)"
      #statusActions
    ></recrewt-application-move-menu>
  </ng-template>
</mat-menu>
