import {
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { createPopper, Instance } from '@popperjs/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CustomFormControlComponent } from '@shared/components/forms/custom-form-control/custom-form-control.component';

@Component({
  template: '',
})
export class PopupComponent extends CustomFormControlComponent implements OnDestroy {
  @Output() opened = new EventEmitter();

  @Output() closed = new EventEmitter();

  protected view?: EmbeddedViewRef<any>;

  protected yOffset = 0;

  protected popperRef?: Instance;

  constructor(private vcr: ViewContainerRef, private zone: NgZone, private cdr: ChangeDetectorRef) {
    super();
  }

  get isOpen(): boolean {
    return !!this.popperRef;
  }

  open(dropdownTpl: TemplateRef<any>, origin: HTMLElement): void {
    if (this.popperRef) {
      return;
    }
    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const popup = this.view?.rootNodes[0];

    document.body.appendChild(popup);
    popup.style.width = `${origin.offsetWidth}px`;

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, popup, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, this.yOffset],
            },
          },
        ],
      });
    });
    this.handleClickOutside();
    this.opened.emit();
  }

  close(): void {
    this.closed?.emit();
    this.popperRef?.destroy();
    this.view?.destroy();
    this.view = undefined;
    this.popperRef = undefined;
  }

  ngOnDestroy(): void {
    this.close();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  writeValue(obj: any): void {}

  private handleClickOutside(): void {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => {
          const origin = this.popperRef?.state.elements.reference as HTMLElement;
          return !origin?.contains(target as HTMLElement);
        }),
        takeUntil(this.closed),
      )
      .subscribe((event) => {
        const popup = this.popperRef?.state.elements.popper;
        if (
          !!popup?.contains(event.target as HTMLElement) ||
          (event.target as HTMLElement).className.includes('mat-calendar')
        ) {
          return;
        }
        this.close();
        this.cdr.detectChanges();
      });
  }
}
