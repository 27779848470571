import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatchStore } from '@data/match/match.store';
import { MatchQuery } from '@data/match/match.query';
import { Match } from '@data/match/match.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ValidationStatus } from '@core/constants/match/status';
import { DataBaseService } from '@data/data.base.service';

@Injectable({
  providedIn: 'root',
})
export class MatchService extends DataBaseService<Match> {
  constructor(private matchStore: MatchStore, private matchQuery: MatchQuery, http: HttpClient) {
    super(http, matchStore, matchQuery, 'matches');
  }

  updateStatus(id: string, validationStatus: ValidationStatus): Observable<any> {
    return this.http
      .put(`${this.url}/validationStatus/${id}`, { validationStatus })
      .pipe(tap(() => this.matchStore.update(id, { validationStatus })));
  }

  generateMatchesForUser(
    user: string,
    session?: string,
    general = false,
    reduceBy = 0.15,
  ): Observable<any> {
    return this.http.post(`${this.url}/generate/${user}`, { general, reduceBy, session });
  }
}
