<div class="main height-match-parent">
  <recrewt-rail-sidenav
    [expandDelay]="1500"
    [expandedWidth]="350"
    [preventOpenOnClick]="true"
    [primary]="true"
    class="height-match-parent"
  >
    <div class="height-match-parent width-match-parent" sidenav>
      <mat-nav-list disableRipple>
        <a
          mat-list-item
          queryParamsHandling="merge"
          routerLink="/company/analytics"
          routerLinkActive="active"
        >
          <mat-icon class="on-primary" mat-list-icon>business</mat-icon>
          <h4 class="mat-subheading-1 mat-body-1 on-primary" mat-line>
            {{ 'SIDENAV.company' | translate }}
          </h4>
        </a>
        <a
          mat-list-item
          queryParamsHandling="merge"
          routerLink="/application/overview"
          routerLinkActive="active"
        >
          <mat-icon class="on-primary" mat-list-icon>work</mat-icon>
          <h4 class="mat-subheading-1 mat-body-1 on-primary" mat-line>
            {{ 'SIDENAV.application' | translate }}
          </h4>
        </a>
        <a
          mat-list-item
          queryParamsHandling="merge"
          routerLink="/matching/matches"
          routerLinkActive="active"
        >
          <mat-icon class="on-primary" mat-list-icon>auto_awesome</mat-icon>
          <h4 class="mat-subheading-1 mat-body-1 on-primary" mat-line>
            {{ 'SIDENAV.home' | translate }}
          </h4>
        </a>
      </mat-nav-list>
    </div>
    <ng-container sidenav-content>
      <ng-content></ng-content>
    </ng-container>
  </recrewt-rail-sidenav>
</div>
