import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { CompanyState, CompanyStore } from '@data/company/company.store';
import { Company } from '@data/company/company.model';

@Injectable({ providedIn: 'root' })
export class CompanyQuery extends QueryEntity<CompanyState, Company> {
  constructor(protected store: CompanyStore) {
    super(store);
  }
}
