<div class="width-match-parent">
  <div style="border-radius: 16px" class="gradient-bg-hor p-2 m-h-3 mat-elevation-z3" fxFlex="grow" fxLayoutAlign="space-between start">
    <div class="p-r-2" fxFlex="grow">
      <p class="no-pad mat-subheading-1 on-primary">{{title}}</p>
      <p class="no-pad on-primary">{{desc}}</p>
    </div>
    <div class="arrow-container" fxLayoutAlign="center center">
      <mat-icon style="color: white" class="no-pad">play_arrow_back</mat-icon>
    </div>
  </div>
</div>
