import { Pipe, PipeTransform } from '@angular/core';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';
import { getMatchStatusTagConfig } from '@core/constants/match/match-status';
import {
  getApplicationStatusTag,
  getApprenticeshipStatusTag,
  getExpressStatusTag,
} from '@core/constants/application/application-status';

type StatusOf = 'match' | 'application' | 'express' | 'internship';

@Pipe({
  name: 'statusTag',
})
export class StatusTagPipe implements PipeTransform {
  transform(value: any, type: StatusOf, ...params: any): StatusTagConfig | undefined {
    switch (type) {
      case 'match':
        return getMatchStatusTagConfig(value);
      case 'application':
        return getApplicationStatusTag(value, params);
      case 'express':
        return getExpressStatusTag(value);
      case 'internship':
        return getApprenticeshipStatusTag(value);
    }
    return undefined;
  }
}
