<div
  (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)"
  [attr.aria-labelledby]="_formField.getLabelId()"
  role="group"
>
  <input
    #input
    (dateChange)="onChange(value)"
    (input)="onChange(value)"
    [formControl]="dateControl"
    [matDatepicker]="picker"
    aria-label="Date input"
    fxFlex="grow"
    matInput
  />
  <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
