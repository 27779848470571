import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Identifiable } from '../dnd-table/column-config';

@Component({
  selector: 'recrewt-dnd-match-column',
  templateUrl: './dnd-column.component.html',
  styleUrls: ['./dnd-column.component.scss'],
})
export class DndColumnComponent {
  @Input() items: Identifiable[] = [];

  @Input() itemTpl?: TemplateRef<any>;

  @Input() allowDrop = true;

  @Input() headline = '';

  @Output() moved = new EventEmitter<Identifiable>();

  @Output() itemClicked = new EventEmitter<Identifiable>();

  drop(event: CdkDragDrop<Identifiable[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      if (this.allowDrop) {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        );
      }
      this.moved.emit(event.container.data[event.currentIndex]);
    }
  }
}
